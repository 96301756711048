import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useContentFilters } from "../../hooks/use-content-filters"
import {
  Seo,
  BannerCTA,
  Spinner,
  KeyServices,
  Gallery,
  StickyMenu,
} from "../../components"
import { CaseStudies, DownloadServices, HeaderServices } from "./components"

import { globalHistory } from "@reach/router"
import { useLocation } from "@reach/router"

import "./service-style.scss"

const ServiceView = ({ data, loading }) => {
  const { galleryOptions } = useContentFilters()
  const { t, i18n } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    const { state } = globalHistory.location
    const savedScrollPosition = localStorage.getItem("scrollPosition")

    if (state && state.scrollTo) {
      const id = state.scrollTo.replace("#", "")
      const element = document.getElementById(id)
      if (element) element.scrollIntoView()
    }
    if (savedScrollPosition) {
      // Wait a short time before scrolling
      setTimeout(() => {
        // Scroll to the saved position
        window.scrollTo(0, parseInt(savedScrollPosition))
        // Clear the saved scroll position
        localStorage.removeItem("scrollPosition")
      }, 100) // Adjust this delay as needed
    }
  }, [])

  const menu = [
    {
      name: t("service.key.title"),
      id: "key-services",
    },
  ]

  // Check for presence of gallery data and conditionally add to menu
  if (
    data &&
    data.acf &&
    data.acf.rows &&
    data.acf.rows.some(layout => layout.acf_fc_layout === "gallery")
  ) {
    menu.push({
      name: t("service.gallery.title"),
      id: "gallery",
    })
  }

  // Add the case studies item to the menu
  menu.push({
    name: t("service.case-study.title"),
    id: "case-studies",
  })
  if (loading) {
    return <Spinner loading={loading} />
  }

  const btnContent = _.head(
    _.filter(_.get(data, "acf.rows"), { acf_fc_layout: "hero_two_images_copy" })
  )

  return (
    <React.Fragment>
      <div className="service__container">
        <StickyMenu
          menu={menu}
          external={true}
          slug={location.pathname.slice(1)}
          btnContent={btnContent}
        />
        <Seo
          title={_.get(data, "title")}
          description={_.get(data, "excerpt")}
        />
        <div>
          {_.map(_.get(data, "acf.rows"), (layouts, idx) => {
            let layout
            switch (true) {
              // Services
              case layouts.acf_fc_layout === "hero_two_images_copy":
                layout = (
                  <div key={idx} className="service__content service__key">
                    <HeaderServices
                      title={_.get(data, "title")}
                      data={layouts}
                      t={t}
                    />
                  </div>
                )
                break

              // sub services
              case layouts.acf_fc_layout === "cards":
                layout = (
                  <div key={idx} className="service__content service__services">
                    <KeyServices data={layouts} t={t} />
                  </div>
                )
                break

              // how we do it
              case layouts.acf_fc_layout === "download" &&
                !_.isEmpty(layouts.list):
                layout = (
                  <div key={idx} className="service__content" id="downloads">
                    <DownloadServices data={layouts} t={t} i18n={i18n} />
                  </div>
                )
                break
              case layouts.acf_fc_layout === "gallery":
                layout = (
                  <div key={idx} className="service__content" id="gallery">
                    <Gallery data={layouts} t={t} options={galleryOptions} />
                  </div>
                )
                break
              case layouts.acf_fc_layout === "case_studies_list":
                layout = (
                  <div key={idx} className="service__content" id="case-studies">
                    <CaseStudies data={layouts} t={t} />
                  </div>
                )
                break
              default:
                break
            }
            return layout
          })}
        </div>
      </div>
      <BannerCTA />
    </React.Fragment>
  )
}

export default ServiceView
